<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Категория"
              >
                <el-select
                  v-model="filter.category"
                  class="form-control"
                  name="is-draft"
                  placeholder
                  clearable
                  :loading="loading"
                  @input="updateFilter('category')"
                >
                  <el-option
                    v-for="item in categories"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата от">
                <el-date-picker-input
                  v-model="filter.date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  @change="updateFilter('date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата до">
                <el-date-picker-input
                  v-model="filter.date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  @change="updateFilter('date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Рейс"
              >
                <el-select-clearable
                  v-model="filter.route_id"
                  class="form-control"
                  name="route"
                  placeholder="Начните вводить название рейса"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="getRoutesList"
                  :loading="loading"
                  :disabled="!(filter.category && filter.category === 'route')"
                  @input="updateFilter('route_id')"
                  @clear="getRoutesList"
                >
                  <el-option
                    v-for="item in routes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Аукцион"
              >
                <el-select-clearable
                  v-model="filter.auction_id"
                  class="form-control"
                  name="auction"
                  placeholder="Начните вводить код аукциона"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="getAuctionsList"
                  :loading="loading"
                  :disabled="!(filter.category && filter.category === 'auction')"
                  @input="updateFilter('auction_id')"
                  @clear="getAuctionsList"
                >
                  <el-option
                    v-for="item in auctions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Тендер"
              >
                <el-select-clearable
                  v-model="filter.tender_id"
                  class="form-control"
                  name="tender"
                  placeholder="Начните вводить название тендера"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="getTendersList"
                  :loading="loading"
                  :disabled="!(filter.category && filter.category === 'tender')"
                  @input="updateFilter('tender_id')"
                  @clear="getTendersList"
                >
                  <el-option
                    v-for="item in tenders"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Довереность"
              >
                <el-select-clearable
                  v-model="filter.attorney_id"
                  class="form-control"
                  name="attorney"
                  placeholder="Начните вводить номер доверенности"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="getAttorneysList"
                  :loading="loading"
                  :disabled="!(filter.category && filter.category === 'attorney')"
                  @input="updateFilter('attorney_id')"
                  @clear="getAttorneysList"
                >
                  <el-option
                    v-for="item in attorneys"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <div
      v-if="data.length > 0"
      class="notifications-results"
    >
      <notification-card
        v-for="notification in data"
        :key="notification.id"
        :notification="notification"
      />
      <b-pagination
        v-model="currentPage"
        :total-rows="totalCount"
        :per-page="perPage"
        align="center"
        @change="paginationChange"
      />
    </div>
    <div v-else>
      <p class="text-center">
        Результатов не найдено
      </p>
    </div>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue'
import ElSelectClearable from '../../components/ElSelectClearable.vue'
import {
  customersRoutesList,
  customersContractorDriversList,
  customersAuctionsList,
  customerContractorsTransportVehiclesList,
  notificationsRead,
  notificationsReadNotification,
  downloadFile, customersAttorneysList, customersTendersList,
} from '../../services/api';
import moment from 'moment';
import {arrayStringToNumber} from '../../components/mixins/helpers';
import {queryToUrl, URLToQuery} from '../../services/http';
import NotificationCard from './NotificationCard';


export default {
  name: 'CustomerNotifications',
  components: {NotificationCard, ElSelectClearable, ElDatePickerInput},
  data: function() {
    return {
      categories: [
        {value: null, text: 'Все'},
        {value: 'route', text: 'Рейс'},
        {value: 'attorney', text: 'Довереность'},
        {value: 'auction', text: 'Аукцион'},
        {value: 'tender', text: 'Тендер'},
      ],
      routes: [],
      vehicles: [],
      drivers: [],
      auctions: [],
      tenders: [],
      attorneys: [],
      filter: {
        category: null,
        date_from: null,
        date_to: null,
        route_id: [],
        contractor_driver_id: [],
        contractor_vehicle_id: [],
        auction_id: [],
        tender_id: [],
        attorney_id: [],
        page: 1,
      },
      loading: false,
      data: [],
      perPage: 10,
      currentPage: 1,
      totalCount: 0,
      pickerOptions: {
        firstDayOfWeek: 1,
      },
    };
  },
  created() {
    this.initRouteParams();
  },
  mounted() {
    this.getRoutesList();
    this.getDriverList();
    this.getTransportVehiclesList();
    this.getNotificationsRead();
  },
  methods: {
    downloadFile,
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (field === 'category') {
          this.clearRelatedEntity();
        }
        this.updateRouteParams();
        this.getNotificationsRead();
      }, 800);
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY, H:mm:ss');
    },
    clearRelatedEntity() {
      this.filter.route_id = [];
      this.filter.auction_id = [];
      this.filter.attorney_id = [];
      this.filter.contractor_driver_id = [];
      this.filter.contractor_vehicle_id = [];
    },
    async getRoutesList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await customersRoutesList(
        this.$store.state.user.ownerId,
        params,
      );
      if (response && response.status === 200) {
        this.routes = response.data.items.map((item) => {
          return {value: item.id, text: item.routeCode};
        });
      }
      if (this.filter.route_id.length > 0) {
        this.getRoutesSelectedList();
      }
      this.loading = false;
    },
    async getRoutesSelectedList() {
      this.loading = true;
      const selectedRoutes = {};
      this.filter.route_id.map((item, index) => {
        selectedRoutes['id[' + index + ']'] = item;
      });
      const response = await customersRoutesList(
        this.$store.state.user.ownerId,
        selectedRoutes,
      );
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.routes = this.routes.filter((x) => x.value !== item.id);
          this.routes.unshift({value: item.id, text: item.routeCode});
        });
      }
      this.loading = false;
    },
    async getDriverList(fio) {
      this.loading = true;
      const params = {limit: 100};
      if (fio) {
        params.fio = fio;
      }
      const response = await customersContractorDriversList(
        this.$store.state.user.ownerId,
        params,
      );
      if (response && response.status === 200) {
        this.drivers = response.data.items.map((item) => {
          let fio = item.driver.surname + ' ' + item.driver.name;
          if (item.driver.patronymic) {
            fio = fio + ' ' + item.driver.patronymic;
          }
          return {value: item.id, text: fio};
        });
      }
      if (this.filter.contractor_driver_id.length > 0) {
        this.getSelectedDriverList();
      }
      this.loading = false;
    },
    async getSelectedDriverList() {
      this.loading = true;
      const selectedDrivers = {};
      this.filter.contractor_driver_id.map((item, index) => {
        selectedDrivers['id[' + index + ']'] = item;
      });
      const response = await customersContractorDriversList(
        this.$store.state.user.ownerId,
        selectedDrivers,
      );
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.drivers = this.drivers.filter((x) => x.value !== item.id);
          let fio = item.driver.surname + ' ' + item.driver.name;
          if (item.driver.patronymic) {
            fio = fio + ' ' + item.driver.patronymic;
          }
          this.drivers.unshift({value: item.id, text: fio});
        });
      }
      this.loading = false;
    },
    async getTransportVehiclesList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.brand_or_grn = query;
      }
      const response = await customerContractorsTransportVehiclesList(
        this.$store.state.user.ownerId,
        params,
      );
      if (response && response.status === 200) {
        this.vehicles = response.data.items.map((item) => {
          return {value: item.id, text: item.vehicle.name};
        });
      }
      if (this.filter.contractor_vehicle_id.length > 0) {
        this.getSelectedTransportVehiclesList();
      }
      this.loading = false;
    },
    async getSelectedTransportVehiclesList() {
      this.loading = true;
      const selectedTransportVehicles = {};
      this.filter.contractor_vehicle_id.map((item, index) => {
        selectedTransportVehicles['id[' + index + ']'] = item;
      });
      const response = await customerContractorsTransportVehiclesList(
        this.$store.state.user.ownerId,
        selectedTransportVehicles,
      );
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.vehicles = this.vehicles.filter((x) => x.value !== item.id);
          this.vehicles.unshift({value: item.id, text: item.vehicle.name});
        });
      }
      this.loading = false;
    },
    async getAuctionsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await customersAuctionsList(
        this.$store.state.user.ownerId,
        params,
      );
      if (response && response.status === 200) {
        this.auctions = response.data.items.map((item) => {
          return {value: item.id, text: item.name};
        });
      }
      if (this.filter.auction_id.length > 0) {
        this.getSelectedAuctionList();
      }
      this.loading = false;
    },
    async getSelectedAuctionList() {
      this.loading = true;
      const selectedAuctions = {};
      this.filter.auction_id.map((item, index) => {
        selectedAuctions['id[' + index + ']'] = item;
      });
      const response = await customersAuctionsList(
        this.$store.state.user.ownerId,
        selectedAuctions,
      );
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.auctions = this.auctions.filter((x) => x.value !== item.id);
          this.auctions.unshift({value: item.id, text: item.name});
        });
      }
      this.loading = false;
    },
    async getTendersList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await customersTendersList(
        this.$store.state.user.ownerId,
        params,
      );
      if (response && response.status === 200) {
        this.tenders = response.data.items.map((item) => {
          return {value: item.id, text: item.name};
        });
      }
      if (this.filter.tender_id.length > 0) {
        this.getSelectedTenderList();
      }
      this.loading = false;
    },
    async getSelectedTenderList() {
      this.loading = true;
      const selectedTenders = {};
      this.filter.tender_id.map((item, index) => {
        selectedTenders['id[' + index + ']'] = item;
      });
      const response = await customersTendersList(
        this.$store.state.user.ownerId,
        selectedTenders,
      );
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.tenders = this.tenders.filter((x) => x.value !== item.id);
          this.tenders.unshift({value: item.id, text: item.name});
        });
      }
      this.loading = false;
    },
    async getAttorneysList(number) {
      this.loading = true;
      const params = {limit: 100};
      if (number) {
        params.number = number;
      }
      const response = await customersAttorneysList(
        params,
      );
      if (response && response.status === 200) {
        this.attorneys = response.data.items.map((item) => {
          return {value: item.id, text: item.number};
        });
      }
      if (this.filter.attorney_id.length > 0) {
        this.getSelectedAttorneyList();
      }
      this.loading = false;
    },
    async getSelectedAttorneyList() {
      this.loading = true;
      const selectedAttorneys = {};
      this.filter.attorney_id.map((item, index) => {
        selectedAttorneys['id[' + index + ']'] = item;
      });
      const response = await customersAttorneysList(
        selectedAttorneys,
      );
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.attorneys = this.attorneys.filter((x) => x.value !== item.id);
          this.attorneys.unshift({value: item.id, text: item.number});
        });
      }
      this.loading = false;
    },
    async getNotificationsReadNotification(notification) {
      notification.read = true;
      this.loading = true;
      await notificationsReadNotification(
        this.$store.state.user.userId,
        notification.id,
      );
      this.loading = false;
    },
    async getNotificationsRead() {
      this.loading = true;
      const response = await notificationsRead(
        this.$store.state.user.userId,
        this.updateRouteParams(),
      );
      if (response && response.status === 200) {
        this.totalCount = response.data.totalCount;
        this.data = response.data.items.map((item) => {
          switch (item.action) {
          case 'success':
            item.class = 'bg-success';
            break;
          case 'add':
            item.class = 'bg-success';
            break;
          case 'danger':
            item.class = 'bg-danger';
            break;
          case 'declined':
            item.class = 'bg-danger';
            break;
          case 'update':
            item.class = 'bg-info';
            break;
          default:
            item.class = 'bg-info';
            break;
          }

          return item;
        });
      }
      this.loading = false;
    },
    paginationChange(page) {
      this.filter.page = page;
      this.getNotificationsRead();
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.category !== null) {
        res.class = this.filter.category;
      }
      if (this.filter.route_id.length) {
        res.route_id = this.filter.route_id;
      }
      if (this.filter.date_from) {
        res.date_from = moment(this.filter.date_from).format();
      }
      if (this.filter.date_to) {
        res.date_to = moment(this.filter.date_to).format();
      }
      if (this.filter.contractor_driver_id.length) {
        res.contractor_driver_id = this.filter.contractor_driver_id;
      }
      if (this.filter.contractor_vehicle_id.length) {
        res.contractor_vehicle_id = this.filter.contractor_vehicle_id;
      }
      if (this.filter.auction_id.length) {
        res.auction_id = this.filter.auction_id;
      }
      if (this.filter.tender_id.length) {
        res.tender_id = this.filter.tender_id;
      }
      if (this.filter.attorney_id.length) {
        res.attorney_id = this.filter.attorney_id;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params});
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('route_id')) {
          params.route_id = arrayStringToNumber(params.route_id);
        }
        if (params.hasOwnProperty('contractor_driver_id')) {
          params.contractor_driver_id = arrayStringToNumber(
            params.contractor_driver_id,
          );
        }
        if (params.hasOwnProperty('contractor_vehicle_id')) {
          params.contractor_vehicle_id = arrayStringToNumber(
            params.contractor_vehicle_id,
          );
        }
        if (params.hasOwnProperty('class')) {
          params.category = params.class;
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        Object.assign(this.filter, params);
        this.$store.commit('setFilterParams', {componentName: this.$options.name, params});
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params) {
          Object.assign(this.filter, params);
        }
      }
    },
  },
};
</script>

<style lang="scss" >
.notifications-results {
  .bg-success,
  .bg-info,
  .bg-danger {
    .card-header {
      &[aria-expanded] {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.notifications-card {
  margin-bottom: 8px;

  .card-body {
    position: relative;
  }

  &__link {
    color: #fff;
    text-decoration: underline;

    &:hover {
      color: #dcd9c8;
      text-decoration: none;
    }
  }
  &__check-button {
    position: absolute;
    top: 10px;
    right: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 24px;
    height: 21px;
    padding: 0;
    background-color: transparent;
    border: 0;
    font: normal normal normal 10px/1 FontAwesome;
    font-size: 16px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    cursor: pointer;
    transition: color 0.33s;

    &:before {
      content: "\F096";
    }

    &:focus {
      outline: 0;
    }
    &:hover {
      color: #dcd9c8;
    }

    &.checked {
      color: #dcd9c8;

      &:before {
        content: "\F046";
      }
    }
  }
}
</style>
