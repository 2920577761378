<template>
  <b-card
    no-body
    class="notifications-card"
    :class="notification.class"
  >
    <b-card-header v-b-toggle="'notification-' + notification.id">
      <a
        v-if="notification.link"
        class="notifications-card__link"
        :href="notification.link"
        target="_blank"
      >{{ notification.title }}</a>
      <span v-else>{{ notification.title }}</span>
    </b-card-header>
    <b-collapse
      :id="'notification-' + notification.id"
      class="card-body"
    >
      <p
        v-if="notification.actionList && notification.actionList.length > 0"
        class="mb-0"
      >
        {{ notification.body }}. Список изменений:
      </p>
      <p
        v-else
        class="mb-0"
      >
        {{ notification.body }}
      </p>
      <ul v-if="notification.actionList && notification.actionList.length > 0">
        <li
          v-for="(item, index) in notification.actionList"
          :key="`notification-${index}`"
        >
          {{ item }}
        </li>
      </ul>
      <button
        v-b-tooltip.hover.left="'Отметить как «Прочитанное»'"
        class="notifications-card__check-button"
        :class="{'checked' : notification.read}"
        @click="notificationsReadNotification(notification)"
      />
    </b-collapse>
  </b-card>
</template>

<script>
import {notificationsReadNotification} from '../../services/api';
import moment from 'moment';
export default {
  name: 'NotificationCard',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async notificationsReadNotification(notification) {
      notification.read = true;
      this.loading = true;
      await notificationsReadNotification(
        this.$store.state.user.userId,
        notification.id,
      );
      this.loading = false;
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY, H:mm:ss');
    },
  },
};
</script>
